<template>
  <Toast />
  <ul class="list-none p-0 m-0 flex align-items-center font-medium mb-3">
    <li>
      <a class="text-500 no-underline line-height-3 cursor-pointer">REPORTES</a>
    </li>
    <li class="px-2">
      <i class="pi pi-angle-right text-500 line-height-3"></i>
    </li>
    <li>
      <span class="text-900 line-height-3 active">Stock General</span>
    </li>
  </ul>
  <div class="surface-section px-4 py-5 md:px-12 lg:px-12">
    <div
      class="flex align-items-center flex-column lg:justify-content-center lg:flex-row"
    >
      <h4><strong>REPORTE GENERAL DE STOCK</strong></h4>
    </div>
    <div class="col-12 p-fluid formgrid grid card p-fondo">
      <div class="field col-12 md:col-12">
        <label for="producto"
          ><strong>BUSCAR PRODUCTO EN GENERAL: </strong></label
        >
        <Panel
          toggleable
          :collapsed="false"
          @update:collapsed="actualizarPanelMasFiltros"
        >
          <template #header>
            <div class="p-inputgroup">
              <InputText
                v-model="productoSelected"
                placeholder="Buscar Producto"
                v-tooltip.top="'Buscar Producto por Nombre'"
                @keyup.enter="BuscarFiltro"
              />
              <Button
                :disabled="!productoSelected"
                icon="pi pi-search"
                class="p-button-primary"
                @click="BuscarFiltro"
              />
            </div>
          </template>
          <div class="grid">
            <div class="col-12 flex flex-align-left">
              <div
                v-for="filtro of buscarProductoPorListado"
                :key="filtro.value"
                class="col-3 pr-1 mr-1"
              >
                <Checkbox
                  v-model="buscarProductoPorSelected"
                  :inputId="filtro.value"
                  name="filtro"
                  :value="filtro.value"
                />
                <label class="ml-1" :for="filtro.value">{{
                  filtro.label
                }}</label>
              </div>
            </div>
          </div>
        </Panel>
      </div>
    </div>
    <div class="col-12">
      <DataTable
        ref="reporte_stock_general"
        key="id"
        :value="stocks_general"
        responsiveLayout="scroll"
        :loading="enviando"
        class="p-datatable-sm"
        responsive="true"
        stripedRows
        showGridlines
        selectionMode="single click"
      >
        <template #loading
          ><div class="flex align-items-center justify-content-center">
            <ProgressSpinner /></div
        ></template>
        <template #empty>
          <span class="p-invalid">
            Debe aplicar filtros para ver resultados en la tabla!</span
          >
        </template>
        <Column header="#">
          <template #body="slotProps">
            {{ 1 + slotProps.index }}
          </template>
        </Column>
        <Column field="fabrica_nombre" header="LABORATORIO"></Column>
        <Column
          field="descripcion"
          header="DESCRIPCIÓN/PRODUCTO"
          style="font-weight: bold"
        />
        <Column class="text-center" field="stock_actual" header="STOCK">
          <template #body="{ data }">
            <div :class="stockClass(data)">
              {{ sinDecimal(data.stock_actual) }}
            </div>
          </template>
        </Column>
        <Column field="caso_uso" header="PRINCIPIO ACTIVO"></Column>
        <Column
          field="accion_farmacologica"
          header="ACCIÓN FARMACOLÓGICA"
        ></Column>
      </DataTable>
    </div>
  </div>
</template>

<script>
import ProductService from "@/service/ProductService";
import { useAuth } from "@/stores";

export default {
  data() {
    return {
      masFiltrosBusqueda: false,
      buscarProductoPorSelected: ["nombre_comercial"],
      buscarProductoPorListado: [
        { label: "Nombre Comercial", value: "nombre_comercial" },
        { label: "Principio Activo", value: "principio_activo" },
        { label: "Código", value: "codigo" },
        { label: "Acción Farmacológica", value: "accion_farmacologica" },
      ],
      codProdListadoFiltrado: [],
      productosResultados: false,
      productosFiltrados: [],
      stocks_general: [],
      enviando: false,
      productoSelected: null,
      productosListadoFiltrado: [],
      stockClass: (data) => {
        if (data.stock_actual <= 0) {
          return "outofstock";
        } else if (data.stock_actual <= 10) {
          return "lowstock";
        } else {
          return "instock";
        }
      },
    };
  },
  productService: null,
  auth: null,
  created() {
    this.productService = new ProductService();
    this.auth = useAuth();
  },
  computed: {
    computedBuscarProductoPorSelected() {
      return this.buscarProductoPorSelected.includes("principio_activo");
    },
  },
  methods: {
    verificarCasoUso() {
      if (this.buscarProductoPorSelected.includes("principio_activo")) {
        console.log("verificarCasoUso");
        return true;
      } else {
        console.log("no verificarCasoUso");
        return false;
      }
    },
    actualizarPanelMasFiltros(e) {
      this.masFiltrosBusqueda = e;
      if (!this.masFiltrosBusqueda) {
        this.limpiarFiltrosBusquedaProducto();
      }
    },
    limpiarFiltrosBusquedaProducto() {
      this.buscarProductoPorSelected = ["nombre_comercial"];
      this.productoSelected = null;
      this.productosListadoFiltrado = [];
    },
    BuscarFiltro() {
      this.stocks_general = [];
      this.enviando = true;
      this.cargarStocksSucursal();
    },
    cargarStocksSucursal() {
      let datos = {
        texto: this.productoSelected,
        producto: this.productoSelected,
        buscarProductoPorSelected: this.buscarProductoPorSelected,
      };
      this.productService
        .filtrarStockGeneral(datos)
        .then((data) => {
          this.stocks_general = data.stocks_general;
          this.enviando = false;
        })
        .catch(() => {
          this.enviando = false;
        });
    },
    convertirNumeroGermanicFormat(numero) {
      return new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 2,
      }).format(numero);
    },
    sinDecimal(numero) {
      return new Intl.NumberFormat("de-DE", {
        minimumFractionDigits: 0,
      }).format(numero);
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/demo/badges.scss";
.p-invalid {
  color: red;
}
.p-fondo {
  background-color: #7bffae;
  border: 1px solid #000000;
  border-bottom: 2px solid #000000;
}
.outofstock {
  font-weight: 700;
  color: #000000;
  background-color: #ff5252;
  padding: 0 0.5em;
  border-radius: 0.5em;
}

.lowstock {
  font-weight: 700;
  color: #000000;
  background-color: #fbc02d;
  padding: 0 0.5em;
  border-radius: 0.5em;
}

.instock {
  font-weight: 700;
  color: #000000;
  background-color: #7bffae;
  padding: 0 0.5em;
  border-radius: 0.5em;
}
</style>
